body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: Raleway;
  font-style: normal;
  color: #004170;
}
h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

/* Gotham */
@font-face {
  font-family: "Gotham Book";
  src: url("fonts/Gotham/Gotham-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Bold";
  src: url("fonts/Gotham/Gotham-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* Arno Pro */
@font-face {
  font-family: "ArnoPro Regular";
  src: url("fonts/ArnoPro/ArnoPro-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArnoPro Bold";
  src: url("fonts/ArnoPro/ArnoPro-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
